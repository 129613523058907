import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import '../font/style.css'
import './index.css'

class IndexPage extends React.Component {
  state = {
    iconData: null,
    term: "apple"
  }

  componentDidMount(){
    this.fetchByTerm("apple");
  }

  fetchByTerm(term){
    if(this.timeout){
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      fetch(`https://api.svgapi.com/v1/Ty5WcDa63E/list/?search=${term}&limit=20`).then(res => res.json()).then(resJson => {
        this.setState({iconData: resJson});
      });
    }, 200);
  }

  renderIcons(){
    if(this.state.iconData){
      return this.state.iconData.icons.map((icon, i) => {
        return (<img src={icon.url} key={icon.id}/>);
      })
    }
  }

  render(){
    return (
      <Layout>
        <SEO title="svgapi - Pricing" description="See the pricing plans for our SVG icon API."/>
        <div className="register">
          <div className={this.state.registered ? "side registered" : "side"}>
            <div className="sideInner">
              <div className="header">
                <a href="/"><img src={require("../images/logo_white.svg")} className="logo"/></a>
              </div>
              <div className="sideInfo">
                <h1>Pricing</h1>
                <p>See our pricing options on this page.</p>
                <p><a style={{color: "#ddd"}} href="/contact">Contact us</a> if you have a more call value intensive project to get a quote.</p>
              </div>
              <div className="sideLinks">
                <a href="https://panel.svgapi.com">Admin</a>
                <Link to="/pricing">Pricing</Link>
                <Link to="/documentation">Documentation</Link>
                <Link to="/contact">Contact Us</Link>
              </div>
            </div>

          </div>


          <div className="sideHolder"></div>
          <div className="form">
            <div className="formInner">

              <Link to="/register/?plan=starter">
                <div className="pricePoint">
                  <h2>Starter Plan</h2>
                  <p>Great for a small project just starting out with 1-2k users monthly on average.</p>
                  <ul>
                    <li>5,000 calls/mo</li>
                    <li>Free trial for the first month</li>
                    <li>Unlimited domains.</li>
                    <li>Unlimited CDN calls.</li>
                  </ul>
                  <div className="priceInUSD">
                  $9,90
                  <span>FREE FOR FIRST WEEK</span>
                  </div>
                </div>
              </Link>

              <Link to="/register/?plan=maker">
                <div className="pricePoint">
                  <h2>Maker Plan <span>BEST VALUE</span></h2>
                  <p>Great for projects that starts to take off with 10-20k users monthly on average.</p>
                  <ul>
                    <li>50,000 calls/mo</li>
                    <li>Unlimited domains.</li>
                    <li>Unlimited CDN calls.</li>
                  </ul>
                  <div className="priceInUSD">$24,90</div>
                </div>
              </Link>

              <Link to="/register/?plan=professional">
                <div className="pricePoint">
                  <h2>Professional Plan</h2>
                  <p>Great established or call intensive projects with 100-200k users monthly on average.</p>
                  <ul>
                    <li>500,000 calls/mo</li>
                    <li>Unlimited domains.</li>
                    <li>Unlimited CDN calls.</li>
                    <li>Extra implementation support *</li>
                  </ul>
                  <div className="priceInUSD">$149,90</div>
                </div>
              </Link>

              <br/>
              <h2>Social Responsibility</h2>
              <p>If you are working on a non-commercial social responsibility project related to accesibility for disable people or with another great cause, we are happy to give you the API for free, forever. Please <a href="/contact">contact us</a> and tell us about your project.</p>


              <p>* Support for implementation will be delivered in 7 business days, and for only your already established projects that uses JavaScript.</p>

              <footer style={{fontSize: "0.9em", paddingTop: 20, paddingBottom: 30}}>
                Copyright © {new Date().getFullYear()}, {` `} Dream Oriented Ltd.<br/>
                <small>We collect payments using Paddle.com's services with the company name Dream Oriented Ltd.</small>
                <br/>
                <small><a href="https://paddle.com/legal-buyers/" target="_blank">Paddle Buyer Terms and Conditions</a></small>
              </footer>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
