/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import "../font/style.css"

const Layout = ({ children, noFooter }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />

      <main>{children}</main>

      {!true &&
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >

            <footer style={{fontSize: "0.9em", paddingTop: 20, paddingBottom: 30}}>
              Copyright © {new Date().getFullYear()}, {` `} Dream Oriented Ltd.<br/>
              <small>We collect payments using Paddle.com's services with the company name Dream Oriented Ltd.</small>
              <br/>
              <small><a href="https://paddle.com/legal-buyers/" target="_blank">Paddle Buyer Terms and Conditions</a></small>
            </footer>
        </div>
      }
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
